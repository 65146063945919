<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="productImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400 textBox" style="white-space: pre-wrap">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
// import productImg1 from "@/page/pacwallet/components/img/4_1.png";
import productImg2 from "@/page/wisementpresta/components/img/4_2.png";

export default {
  data() {
    return {
      // productImg1: productImg1,
      productImg2: productImg2,

      altText: "图片",
      text: `chúng ta tôn trọng và bảo vệ quyền riêng tư của người dùng. Chính sách bảo mật này sẽ cho bạn biết những thông tin chúng tôi thu thập, cách chúng tôi thu thập và sử dụng thông tin và cách chúng tôi có thể bảo vệ an toàn thông tin.

Bạn bắt buộc phải đọc và đồng ý tất cả các điều khoản trong chính sách bảo mật này trước khi trở thành người dùng của chúng ta. Chính sách này lập tức có hiệu lực sau khi bạn đăng ký trở thành người dùng chúng ta, đồng thời ràng buộc bạn với chúng ta.

I. Những thông tin người dùng mà chúng tôi thu thập
Khi cung cấp dịch vụ, chúng tôi có thẻ thu thập, lưu trữ và sử dụng thông tin liên quan đến bạn và yêu cầu sự cấp phép của bạn. Nếu bạn không 
cung cấp thông tin có liên quan, bạn sẽ không thể đăng ký trở thành người dùng của chúng tôi, không thể sử dụng dịch vụ mà chúng tôi cung cấp hoặc không thể đạt được hiệu quả dịch vụ mong muốn. Dịch vụ của chúng tôi là hoàn toàn tự nguyện, trừ khi bạn chọn sử dụng dịch vụ của chúng ta, nếu không thì bạn không cần cung cấp những thông tin này.

1. Thông tin cá nhân mà bạn cung cấp

• Thông tin cá nhân có liên quan mà bạn cung cấp cho chúng tôi khi đăng ký tài khoản thông qua ứng dụng hoặc sử dụng các dịch vụ của 
chúng tôi, chẳng hạn như số điện thoại, số chứng minh thư, công ty, chức vụ, số điện thoại người tham chiếu, số thẻ ngân hàng, v.v.

2. Thông tin của bạn mà chúng tôi thu được:

• Thông tin thiết bị là những thông tin liên quan đến thiết bị điện thoại và Sim điện thoại trong quá trình sử dụng ứng dụng, ví dụ như Mã thiết bị, địa chỉ IP, Trạm cơ sở, phiên bản App, thông tin thiết bị mạng, sổ seri thiết bị, danh sách ứng dụng đã cài đặt, thông tin danh bạ, SSID v.v.

• Thông tin vị trí GPS, là thông tin về vĩ độ, kinh độ và vị trí của bạn khi bạn bật chức năng định vị GPS của thiết bị và sử dụng các dịch vụ liên quan của chúng tôi;

• Dữ liệu hành vi là dữ liệu hành vi thao tác khi bạn sửa dụng nền tảng của chúng ta, ví dụ như thao tác đăng ký, đăng nhập, xin vay của bạn trên ứng dụng, số liệu khi bạ nhấp trên màn hình. Nếu cần thiết, chúng tôi sẽ truy cập các dịch vụ của nền tảng thống kê dữ liệu của bên thứ ba 
bằng cách áp dụng điểm chôn của sự kiện;

•Thông tin đơn đăng ký và giao dịch là việc chúng tôi tiến hành phân tích thông tin về số liệu giao dịch của bạn khi bạn sử dụng nền tảng của chúng ta ví dụ như đăng ký, giải ngân...để hình thành kho số liệu tín dụng của người dùng;

•Thông tin khác ghi lại thao tác trên App, ví dụ như ghi chép việc thay đổi số chứng minh thư hoặc loại chứng minh thư.

3. Thông tin của bạn có thể được cung cấp cho các tổ chức đối tác bên thứ ba

• Nếu cần thiết và dưới sự cấp phép của bạn, chúng tôi có thể thông qua bên thứ ba hợp pháp (Bao gồm nhưng không hạn chế bới việc thông qua nền tảng dịch vụ dữ liệu hợp pháp, cơ quan tín dụng quốc gia) để tra cứu thông tin báo cáo tín dụng của bạn, ví dụ thông tin chứng minh thư, thông tin công việc, tình trạng thu nhập, tình trạng nợ nần và trạng thái tín dụng của bạn...

II. Chúng tôi sử dụng thông tin người dùng như thế nào?
• Dùng để thẩm định khoản vay, đánh giá tín dụng, đảm bảo tính chân thực và có hiệu lực của từng khoản vay.

• Hiểu cách bạn truy cập và sử dụng các sản phẩm và dịch vụ của chúng tôi để tối ưu hóa và nâng cao quy trình và trải nghiệm sản phẩm của chúng tôi;

• Giúp chúng tôi hiểu thói quen và sở thích sử dụng của người dùng và cung cấp dịch vụ cho vay chính xác hơn;

• Giúp chúng tôi đảm bảo rằng việc cho vay được thực hiện một cách có trật tự và có thể thu hồi khoản vay.

III. Chúng tôi có thể chia sẻ thông tin người dùng với các bên thứ ba
Chúng tôi có thể chia sẻ thông tin cá nhân của bạn trong các tình huống sau:

• Nếu khoản vay của bạn quá hạn nghiêm trọng hoặc cá nhân cố ý làm mất liên lạc mà chưa trả tiền gốc và lãi, chúng tôi sẽ tiết lộ thông tin 
khoản vay và thông tin quá hạn của bạn cho công cộng hoặc đối tác trong phạm vi được pháp luật cho phép.

• Khi bạn có những hành vi vi phạm hợp đồng hoặc những hành vi ác ý trong quá trình sử dụng chúng ta gây ảnh hưởng xấu đến những người dùng khác và dẫn đến tố tụng, chúng tôi sẽ tiết lộ thông tin khoản vay và thông tin quá hạn của bạn.

• Khi bạn quá hạn một cách nghiêm trọng hoặc cố ý làm mất liên lạc mà chưa trả tiền gốc và lãi theo hợp đồng, nếu cần thiết chúng tôi sẽ ủy 
thác cho bên thu hồi nợ, cung cấp thông tin khoản vay, thông tin quá hạn cho cho bên được ủy thác.

• Chúng tôi và công ty liên kết với chúng tôi có thể chia sẻ thông tin của ban cho dối tác, nhà cung cấp dịch vụ, nhà thầu và dại lí (Ví dụ như nhà cung cấp dịch vụ liên lạc đại diện chúng tôi gửi tin nhắn và thông báo, nhà cung cấp dich vụ định vị), để chúng tôi có thể cung cấp và cải thiện dịch vụ cho bạn.

IV. Chúng tôi quản lý và bảo vệ thông tin người dùng như thế nào?
• Chúng tôi sẽ tiến hành lưu trữ thông tin thu được vào máy chủ của chúng ta.

• chúng ta sử dụng công nghệ mới nhất để lưu trữ và bảo vệ thông tin của bạn. Chúng tôi sẽ tuân thủ Điều khoản sử dụng để đảm bảo thông tin của bạn được bảo mật, đồng thời bảo vệ và hạn chế quyền truy cập để bảo vệ bạn khỏi mọi hình thức lừa đảo. Tuy nhiên, xin vui lòng hiểu rằng 
do các hạn chế công nghệ và những thủ đoạn ác ý có thể tồn tại, trên Internet, ngay cả khi bạn làm mọi cách có thể để tăng cường các biện 
pháp bảo mật, bạn luôn không thể đảm bảo an toàn 100%. Vui lòng chịu trách nhiệm bảo vệ số tài khoản và ID người dùng và mật khẩu của bạn để đảm bảo rằng những người dùng khác không thể truy cập vào tài khoản của bạn.

• Nói rõ: chúng ta sẽ không bán hoặc cho thuê bất kỳ dữ liệu thông tin người dùng nào cho bên thứ ba trừ khi chúng tôi thay đổi chính sách , đồng thời sẽ thông báo cho bạn và xin sự cấp phép của bạn.

V. Độ tuổi
Để có thể sử dụng dịch vụ của chúng tôi, bạn phải từ 18-50 tuổi. Nếu bạn không đủ điều kiện thì bạn không được phép sử dụng dịch vụ của chúng tôi.

VI. Thay đổi chính sách bảo mật
•Do những thay đổi về luật pháp và quy định, và để theo kịp sự phát triển mới và xu hướng có thể có trên Internet nên chúng tôi vẫn có quyền quyết định thay đổi chính sách, trong đó có thể có một số thay đổi làm giảm quyền lợi của bạn trong “Chính sách bảo mật”.Ở tình huống này, 
nếu bạn vẫn tiếp tục sử dụng dịch vụ của chúng tôi, tức là đã đồng ý với nhận sự ràng buộc của “Chính sách bảo mật” này.

• Thông báo: Nếu có bất kỳ thay đổi nào trong chính sách bảo mật, chúng tôi sẽ thông báo qua tin nhắn hoặc ứng dụng.

VII. Hợp nhất
Chính sách bảo mật này được đính kèm với các yêu cầu sử dụng ứng dụng của chúng tôi. Nếu bạn đồng ý sử dụng dịch vụ cho vay của chúng 
tôi, Chính sách này phải được hợp nhất và là một phần không thể thiếu trong Thỏa thuận cho vay của chúng ta.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1,
.img2 {
  overflow: hidden;
  width: 100%;
}
.img2{
  margin-top: 80px;
  margin-left: 120px;
  margin-right: 120px;
}
.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1,
.imgItem2 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgBox2{
  margin-bottom: 100px;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.textBox{
  margin-top: 50px;
  margin-left: 149px;
  margin-right: 149px;
  font-size: 17px;
  color: #666666;
}
</style>
